exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-bloglist-page-js": () => import("./../../../src/templates/bloglist-page.js" /* webpackChunkName: "component---src-templates-bloglist-page-js" */),
  "component---src-templates-book-page-js": () => import("./../../../src/templates/book-page.js" /* webpackChunkName: "component---src-templates-book-page-js" */),
  "component---src-templates-books-page-js": () => import("./../../../src/templates/books-page.js" /* webpackChunkName: "component---src-templates-books-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-court-law-page-js": () => import("./../../../src/templates/court-law-page.js" /* webpackChunkName: "component---src-templates-court-law-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-introduction-page-js": () => import("./../../../src/templates/introduction-page.js" /* webpackChunkName: "component---src-templates-introduction-page-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-newslist-page-js": () => import("./../../../src/templates/newslist-page.js" /* webpackChunkName: "component---src-templates-newslist-page-js" */)
}

